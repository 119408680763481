import React, {useRef} from 'react';
import Gr8 from '@/pages/gr8-sports/gr8';
import {useFocusEffect} from '@react-navigation/native';
import {goTo} from '@/utils';
import {homePage} from '@/config';

const SportsContainer = () => {
  const authFailed = useRef(false);
  useFocusEffect(() => {
    if (authFailed.current) {
      goTo('Login', {
        backPage: homePage,
        sucessPage: homePage,
      });
      authFailed.current = false;
    }
  });
  return (
    <Gr8
      onAuthFailed={() => (authFailed.current = true)}
      singleAppPage={false}
    />
  );
};

export default SportsContainer;
